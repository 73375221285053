import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendarAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

// Sample data for the chart
const data = [
  { name: 'Jan', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 2210, amt: 2290 },
  { name: 'Mar', uv: 200, pv: 2290, amt: 2000 },
  { name: 'Apr', uv: 278, pv: 2000, amt: 2181 },
  { name: 'May', uv: 189, pv: 1890, amt: 2500 },
  { name: 'Jun', uv: 239, pv: 3490, amt: 2100 },
];

const Home = () => {
  return (
    <div className="p-6 grid gap-6">
      <h1 className="text-4xl font-extrabold text-gray-900 mb-6">Dashboard</h1>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {/* Card 1: New Jobs */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">New Jobs</h2>
          <p className="text-sm text-gray-500 mb-4">Overview of new job openings.</p>
          <div className="grid gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faBriefcase} className="h-8 w-8 text-black" />
                <div>
                  <div className="font-medium text-sm">Software Engineer</div>
                  <div className="text-xs text-gray-500">Full-time</div>
                </div>
              </div>
              <button className="text-black hover:text-gray-800 font-semibold">View</button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faBriefcase} className="h-8 w-8 text-black" />
                <div>
                  <div className="font-medium text-sm">Marketing Manager</div>
                  <div className="text-xs text-gray-500">Full-time</div>
                </div>
              </div>
              <button className="text-black hover:text-gray-800 font-semibold">View</button>
            </div>
          </div>
        </div>

        {/* Card 2: New Candidates */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">New Candidates</h2>
          <p className="text-sm text-gray-500 mb-4">Overview of new candidate applications.</p>
          <div className="grid gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faUser} className="h-8 w-8 text-black" />
                <div>
                  <div className="font-medium text-sm">John Doe</div>
                  <div className="text-xs text-gray-500">Software Engineer</div>
                </div>
              </div>
              <button className="text-blue-500 hover:text-blue-700 font-semibold">Review</button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faUser} className="h-8 w-8 text-black" />
                <div>
                  <div className="font-medium text-sm">Jane Smith</div>
                  <div className="text-xs text-gray-500">Marketing Coordinator</div>
                </div>
              </div>
              <button className="text-blue-500 hover:text-blue-700 font-semibold">Review</button>
            </div>
          </div>
        </div>

        {/* Card 3: Global Teams */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">Global Teams</h2>
          <p className="text-sm text-gray-500 mb-4">Overview of your teams across the globe.</p>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <Line type="monotone" dataKey="uv" stroke="#2563eb" />
              <Line type="monotone" dataKey="pv" stroke="#e11d48" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Card 4: Approval Carts */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">Approval Carts</h2>
          <p className="text-sm text-gray-500 mb-4">Submissions that need your approval.</p>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faUser} className="h-8 w-8 text-black" />
              <div>
                <div className="font-medium text-sm">John Doe</div>
                <div className="text-xs text-gray-500">New Hire</div>
              </div>
            </div>
            <button className="text-blue-500 hover:text-blue-700 font-semibold">Approve</button>
          </div>
        </div>

        {/* Card 5: Upcoming Holidays */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">Upcoming Holidays</h2>
          <p className="text-sm text-gray-500 mb-4">Holidays based on your team's locations.</p>
          <div className="grid gap-2">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="h-6 w-6 text-black" />
              <div>Christmas - December 25, 2023</div>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="h-6 w-6 text-black" />
              <div>New Year’s Day - January 1, 2024</div>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="h-6 w-6 text-black" />
              <div>Independence Day - July 4, 2024</div>
            </div>
          </div>
        </div>

        {/* Card 6: Due Payments */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold mb-2">Due Payments</h2>
          <p className="text-sm text-gray-500 mb-4">No due payments at the moment.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
