import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome, 
  faUsers, 
  faBriefcase, 
  faFileContract, 
  faFileInvoiceDollar, 
  faChartBar,
  faMagnifyingGlass  // Added the new icon
} from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ setIsAuthenticated, role }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('role');
    setIsAuthenticated(false);
    navigate('/'); // Redirect to the auth page
  };

  return (
    <div className="w-64 min-h-screen bg-[#101417] shadow-lg flex flex-col justify-between">
      <div>
        <div className="p-5">
          <img 
            src={process.env.PUBLIC_URL + '/logowht1.png'} 
            alt="WeLink Logo" 
            className="h-13 mx-auto" 
            style={{ marginTop: '-50px', marginBottom: '-50px', maxWidth: '70%' }} 
          />
        </div>
        <ul className="flex flex-col p-4 space-y-2">
          {/* Home - Available for all roles */}
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                  : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
              }
            >
              <FontAwesomeIcon icon={faHome} />
              <span className="ml-3 text-lg">Home</span>
            </NavLink>
          </li>

          {/* Admin Tabs */}
          {role === 'admin' && (
            <>
              <li>
                <NavLink
                  to="/recruiting"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="ml-3 text-lg">Recruiting</span>
                </NavLink>
              </li>
              <li>
  <NavLink
    to="/linkedin-scraper"
    className={({ isActive }) =>
      isActive
        ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
        : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
    }
  >
    <FontAwesomeIcon icon={faMagnifyingGlass} />
    <span className="ml-3 text-lg">LinkedIn Scraper</span>
  </NavLink>
</li>
              <li>
                <NavLink
                  to="/active-jobs"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  <span className="ml-3 text-lg">Active Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/jobs"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  <span className="ml-3 text-lg">Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contract"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileContract} />
                  <span className="ml-3 text-lg">Contract</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/invoices"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  <span className="ml-3 text-lg">Invoices</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reports"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  <span className="ml-3 text-lg">Reports</span>
                </NavLink>
              </li>
            </>
          )}

          {/* Employer Tabs */}
          {role === 'employer' && (
            <>
              <li>
                <NavLink
                  to="/active-jobs"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  <span className="ml-3 text-lg">Active Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contract"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileContract} />
                  <span className="ml-3 text-lg">Contract</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/invoices"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  <span className="ml-3 text-lg">Invoices</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reports"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  <span className="ml-3 text-lg">Reports</span>
                </NavLink>
              </li>
            </>
          )}

          {/* Employee Tabs */}
          {role === 'employee' && (
            <>
              <li>
                <NavLink
                  to="/jobs"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faBriefcase} />
                  <span className="ml-3 text-lg">Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contract"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileContract} />
                  <span className="ml-3 text-lg">Contract</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/invoices"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  <span className="ml-3 text-lg">Invoices</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reports"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center p-3 bg-gray-700 text-white rounded-lg"
                      : "flex items-center p-3 text-gray-400 hover:bg-gray-200 hover:text-black rounded-lg transition-all"
                  }
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  <span className="ml-3 text-lg">Reports</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="p-4">
        <button
          onClick={handleLogout}
          className="text-red-500 hover:text-red-700 transition-all w-full text-left p-3 rounded-lg"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Navbar;
