// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Recruiting from './pages/Recruiting';
import ActiveJobs from './pages/ActiveJobs';
import Jobs from './pages/Jobs';
import Contract from './pages/Contract';
import Invoices from './pages/Invoices';
import Reports from './pages/Reports';
import Auth from './components/Auth';
import LinkedInScraper from './pages/LinkedInScraper';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [role, setRole] = useState(localStorage.getItem('role'));

  useEffect(() => {
    const savedRole = localStorage.getItem('role');
    if (savedRole) {
      setRole(savedRole);
      console.log('User Role:', savedRole);
    }
  }, []);

  return (
    <Router>
      {!isAuthenticated ? (
        <Auth 
          setIsAuthenticated={setIsAuthenticated} 
          setRole={setRole}
        />
      ) : (
        <div className="flex">
          <Navbar role={role} setIsAuthenticated={setIsAuthenticated} />
          <div className="flex-1 p-10">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/recruiting" element={<Recruiting />} />
              <Route path="/active-jobs" element={<ActiveJobs />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/contract" element={<Contract />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/linkedin-scraper" element={<LinkedInScraper />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;