// src/pages/ActiveJobs.js

import React from 'react';

const ActiveJobs = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">ActiveJobs</h1>
      {/* Add content here */}
    </div>
  );
};

export default ActiveJobs;
