import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserIcon, MailIcon, LockIcon, BuildingIcon } from 'lucide-react';

const InputWithIcon = React.memo(({ icon: Icon, value, onChange, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 pointer-events-none" />
    <input
      value={value}
      onChange={onChange}
      {...props}
      className="w-full px-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
    />
  </div>
));

const Auth = ({ setIsAuthenticated, setRole }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    username: '',
    role: 'employee',
    companyName: ''
  });
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (field) => (e) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isLogin
      ? `${process.env.REACT_APP_BACKEND_URL || 'https://app.welink.ge'}/api/auth/login/`
      : `${process.env.REACT_APP_BACKEND_URL || 'https://app.welink.ge'}/api/auth/register/`;

    const payload = isLogin
      ? { 
          email: formData.email, 
          password: formData.password 
        }
      : {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          role: formData.role,
          company_name: formData.role === 'employer' ? formData.companyName : '',
        };

    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response && response.data) {
        setMessage(response.data.message);

        if (isLogin) {
          localStorage.setItem('isAuthenticated', 'true');
          const { role } = response.data.user;
          localStorage.setItem('role', role);
          setRole(role);
          setIsAuthenticated(true);
          navigate('/home');
        } else {
          setFormData({
            email: '',
            password: '',
            username: '',
            role: 'employee',
            companyName: ''
          });
          setMessage('Registration successful! Please login.');
          setTimeout(() => {
            setIsLogin(true);
          }, 1500);
        }
      }
    } catch (error) {
      setMessage(error.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <div 
      className="min-h-screen flex items-center justify-center p-4 relative"
      style={{
        backgroundImage: `url('/images/login-bg.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay for blur effect */}
      <div 
        className="absolute inset-0"
        style={{
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />

<div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 w-full max-w-md relative z-10">
        <div className="text-center mb-8">
          <img 
            src="/logoblktr.png" 
            alt="WeLink Logo" 
            className="h-12 mx-auto mb-4"  // Adjust height as needed
          />
          <p className="text-gray-600">Connect, collaborate, and grow your network</p>
        </div>

        <div className="flex mb-6 border-b">
          <button
            type="button"
            className={`w-1/2 pb-4 text-center ${
              isLogin ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
            }`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button
            type="button"
            className={`w-1/2 pb-4 text-center ${
              !isLogin ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
            }`}
            onClick={() => setIsLogin(false)}
          >
            Register
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {!isLogin && (
            <div>
              <label className="block text-gray-700 mb-2">Username</label>
              <InputWithIcon
                icon={UserIcon}
                type="text"
                placeholder="Enter your username"
                value={formData.username}
                onChange={handleInputChange('username')}
                required
              />
            </div>
          )}

          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            <InputWithIcon
              icon={MailIcon}
              type="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange('email')}
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Password</label>
            <InputWithIcon
              icon={LockIcon}
              type="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleInputChange('password')}
              required
              minLength={6}
            />
          </div>

          {!isLogin && (
            <>
              <div>
                <label className="block text-gray-700 mb-2">Role</label>
                <select
                  value={formData.role}
                  onChange={handleInputChange('role')}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  required
                >
                  <option value="employee">Employee</option>
                  <option value="employer">Employer</option>
                </select>
              </div>

              {formData.role === 'employer' && (
                <div>
                  <label className="block text-gray-700 mb-2">Company Name</label>
                  <InputWithIcon
                    icon={BuildingIcon}
                    type="text"
                    placeholder="Enter company name"
                    value={formData.companyName}
                    onChange={handleInputChange('companyName')}
                    required
                  />
                </div>
              )}
            </>
          )}

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {isLogin ? 'Login' : 'Register'}
          </button>
        </form>

        {message && (
          <div
            className={`mt-4 p-3 rounded-lg ${
              message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;