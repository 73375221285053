// src/pages/Invoices.js

import React from 'react';

const Invoices = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Invoices</h1>
      {/* Add content here */}
    </div>
  );
};

export default Invoices;
