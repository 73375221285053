// src/pages/Reports.js

import React from 'react';

const Reports = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Reports</h1>
      {/* Add content here */}
    </div>
  );
};

export default Reports;
