// src/pages/Contracts.js

import React from 'react';

const Contracts = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Contracts</h1>
      {/* Add content here */}
    </div>
  );
};

export default Contracts;
