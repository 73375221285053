import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { FaDownload, FaTrashAlt, FaUpload } from 'react-icons/fa';
import { AiFillLinkedin } from 'react-icons/ai';  // LinkedIn icon from react-icons

const Recruiting = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);  // New state to handle processing after upload
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const modalRef = useRef(null);  // For detecting clicks outside the modal

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://app.welink.ge/api';


  // Fetch candidates
  const fetchCandidates = useCallback(async () => {
    setFetching(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/candidates/`);
      setCandidates(response.data);
    } catch (err) {
      console.error('Failed to fetch candidates:', err);
    } finally {
      setFetching(false);
    }
  }, [BACKEND_URL]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  // Close modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  // Handle file drop for CV upload
  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    multiple: false,
    maxSize: 20 * 1024 * 1024, // 10 MB
  });

  // Handle CV upload with progress bar
  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    setUploadProgress(0);
    setProcessing(false);  // Ensure processing state is false before upload

    try {
      const formData = new FormData();
      formData.append('file', file);

      await axios.post(
        `${BACKEND_URL}/candidates/upload_cv/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
            if (percentCompleted === 100) {
              setProcessing(true);  // Set processing to true when upload completes
            }
          },
        }
      );

      setFile(null);
      setShowModal(false);
      setUploadProgress(0);
      fetchCandidates();
    } catch (err) {
      console.error('Upload failed:', err);
    } finally {
      setUploading(false);
      setProcessing(false);  // Reset processing after completion
      setUploadProgress(0);
    }
  };

  // Handle candidate removal
  const handleDeleteCandidate = async (candidateId) => {
    const confirmed = window.confirm('Are you sure you want to delete this candidate?');
    if (!confirmed) return;

    try {
      await axios.delete(`${BACKEND_URL}/candidates/${candidateId}`);
      fetchCandidates();
    } catch (err) {
      console.error('Failed to delete candidate:', err);
    }
  };

  // Handle CV download
  const handleDownloadCV = async (filename) => {
    try {
      const response = await fetch(`/api/download_cv/${filename}`, {
        method: 'GET',
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error('Failed to download CV:', response.status);
      }
    } catch (error) {
      console.error('Error during CV download:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 h-full">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Recruiting Dashboard</h1>
        <button
          onClick={() => setShowModal(true)}
          className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
        >
          <FaUpload className="mr-2" />
          Upload CV
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Upload CV</h2>
            <div
              {...getRootProps()}
              className={`border-dashed border-2 p-6 rounded-lg text-center cursor-pointer transition duration-300 ${
                isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
              }`}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-blue-500">Drop the file here...</p>
              ) : (
                <p className="text-gray-600">Drag & drop a CV file here, or click to select</p>
              )}
            </div>
            {file && <p className="mt-2 text-sm text-gray-600">Selected file: {file.name}</p>}
            {uploading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
            {processing && (
              <div className="text-blue-500 text-sm mt-2">
                Processing file, please wait...
              </div>
            )}
            <div className="mt-4 flex justify-between">
              <button
                onClick={handleUpload}
                disabled={!file || uploading}
                className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 ${
                  (!file || uploading) && 'opacity-50 cursor-not-allowed'
                }`}
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="text-red-500 px-4 py-2 hover:text-red-600 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 150px)' }}>
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Candidates</h2>
        {fetching ? (
          <p className="text-gray-500">Loading candidates...</p>
        ) : candidates.length === 0 ? (
          <p className="text-gray-500 italic">No candidates found.</p>
        ) : (
          <div className="overflow-x-auto shadow-md rounded-lg">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">LinkedIn</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Skills</th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {candidates.map((candidate) => (
                  <tr key={candidate._id} className="hover:bg-gray-50">
                    <td className="py-4 px-6 whitespace-nowrap">{`${candidate.first_name} ${candidate.surname}`}</td>
                    <td className="py-4 px-6 whitespace-nowrap">{candidate.email}</td>
                    <td className="py-4 px-6 whitespace-nowrap">{candidate.phone}</td>
                    <td className="py-4 px-6 whitespace-nowrap">
                      <span className="bg-yellow-100 text-yellow-800 text-sm font-semibold px-2 py-0.5 rounded">
                        {candidate.role}
                      </span>
                    </td>
                    <td className="py-4 px-6 whitespace-nowrap">
                      {candidate.linkedin ? (
                        <a href={candidate.linkedin} target="_blank" rel="noopener noreferrer">
                          <AiFillLinkedin className="text-blue-600 hover:text-blue-800 text-2xl" />
                        </a>
                      ) : (
                        <span className="text-gray-500 italic">LinkedIn Not Available</span>
                      )}
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex flex-wrap gap-1">
                        {candidate.skills?.slice(0, 5).map((skill, index) => (
                          <span key={index} className="bg-gray-100 text-gray-800 text-xs font-medium px-2 py-0.5 rounded">
                            {skill}
                          </span>
                        ))}
                        {candidate.skills?.length > 5 && (
                          <span className="text-blue-600 hover:text-blue-800 cursor-pointer text-xs" title={candidate.skills.join(', ')}>
                            +{candidate.skills.length - 5} more
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="py-4 px-6 whitespace-nowrap text-sm font-medium">
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleDownloadCV(candidate.filename)}
                          className="text-blue-600 hover:text-blue-900"
                          title="Download CV"
                        >
                          <FaDownload />
                        </button>
                        <button
                          onClick={() => handleDeleteCandidate(candidate._id)}
                          className="text-red-600 hover:text-red-900"
                          title="Delete Candidate"
                        >
                          <FaTrashAlt />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recruiting;
