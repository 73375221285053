// src/pages/Jobs.js

import React from 'react';

const Jobs = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Jobs</h1>
      {/* Add content here */}
    </div>
  );
};

export default Jobs;
