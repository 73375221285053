import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { FaSearch, FaDatabase, FaBuilding, FaSpinner } from 'react-icons/fa';

const LinkedInScraper = () => {
  const [formData, setFormData] = useState({
    title: '',
    skills: '',
    location: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [results, setResults] = useState(null);
  const [savedJobs, setSavedJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [activeTab, setActiveTab] = useState('search');

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://app.welink.ge/api';

  // Event handlers and data fetching functions remain the same
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${BACKEND_URL}/linkedin/scrape`, formData);
      setResults(response.data);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to fetch data');
      console.error('Scraping error:', err);
    } finally {
      setLoading(false);
    }
  }, [BACKEND_URL, formData]);

  const fetchSavedJobs = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/linkedin/jobs`);
      setSavedJobs(response.data.jobs);
    } catch (err) {
      setError('Error loading saved jobs');
      console.error('Fetch saved jobs error:', err);
    }
  }, [BACKEND_URL]);

  const fetchCompanies = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/linkedin/companies`);
      setCompanies(response.data.companies);
    } catch (err) {
      setError('Error loading companies');
      console.error('Fetch companies error:', err);
    }
  }, [BACKEND_URL]);

  useEffect(() => {
    fetchSavedJobs();
    fetchCompanies();
  }, [fetchSavedJobs, fetchCompanies]);

  const renderJobCard = useCallback((job) => (
    <div key={job._id} className="bg-white rounded-lg shadow p-4 mb-4">
      <h3 className="font-medium text-lg text-gray-900">{job.title}</h3>
      <p className="text-gray-600">
        {job.company_name} • {job.location}
      </p>
      <p className="text-gray-500 text-sm mt-1">
        {job.type} • {job.post_date}
      </p>
      {job.benefits && (
        <p className="text-green-600 text-sm mt-2">
          {job.benefits}
        </p>
      )}
      <div className="mt-3 flex justify-end space-x-2">
        <a
          href={job.job_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-700"
        >
          View Job
        </a>
        {job.company_url && (
          <a
            href={job.company_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700"
          >
            Company Profile
          </a>
        )}
      </div>
    </div>
  ), []);

  const renderCompanyCard = useCallback((company) => (
    <div key={company._id} className="bg-white rounded-lg shadow p-4 mb-4">
      <h3 className="font-medium text-lg text-gray-900">{company.name}</h3>
      <p className="text-gray-600">{company.industry}</p>
      <p className="text-gray-500 text-sm mt-1">{company.location}</p>
      <a
        href={company.linkedin_url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-700 mt-2 inline-block"
      >
        View on LinkedIn
      </a>
    </div>
  ), []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">LinkedIn Jobs Scraper</h1>

      {/* Tabs */}
      <div className="flex space-x-2 mb-6 border-b">
        <button
          onClick={() => setActiveTab('search')}
          className={`px-4 py-2 flex items-center ${
            activeTab === 'search'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaSearch className="mr-2" />
          Search Jobs
        </button>
        <button
          onClick={() => setActiveTab('saved')}
          className={`px-4 py-2 flex items-center ${
            activeTab === 'saved'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaDatabase className="mr-2" />
          Saved Jobs
        </button>
        <button
          onClick={() => setActiveTab('companies')}
          className={`px-4 py-2 flex items-center ${
            activeTab === 'companies'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaBuilding className="mr-2" />
          Companies
        </button>
      </div>

      {/* Search Tab */}
      {activeTab === 'search' && (
        <div>
          <div className="bg-white rounded-lg shadow p-6 mb-6">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Job Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="e.g., Software Engineer"
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Skills
                  </label>
                  <input
                    type="text"
                    name="skills"
                    value={formData.skills}
                    onChange={handleInputChange}
                    placeholder="e.g., React, Python"
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    placeholder="e.g., Berlin"
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200 flex items-center justify-center ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <>
                    <FaSpinner className="animate-spin mr-2" />
                    Searching...
                  </>
                ) : (
                  'Search Jobs'
                )}
              </button>
            </form>
          </div>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              {error}
            </div>
          )}

          {results && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold mb-4">
                Found {results.total_jobs} jobs in {formData.location}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {results.jobs?.map(renderJobCard)}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Saved Jobs Tab */}
      {activeTab === 'saved' && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Saved Jobs</h2>
          {savedJobs.length === 0 ? (
            <p className="text-gray-500 text-center py-8">No saved jobs found</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {savedJobs.map(renderJobCard)}
            </div>
          )}
        </div>
      )}

      {/* Companies Tab */}
      {activeTab === 'companies' && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Companies</h2>
          {companies.length === 0 ? (
            <p className="text-gray-500 text-center py-8">No companies found</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {companies.map(renderCompanyCard)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LinkedInScraper;